import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

function SEO({ description, lang, meta, keywords, title, image }) {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={detailsQuery}
      render={({ info }) => {
        const url = `${info.siteUrl}${pathname}`;
        const metaDescription = description || info.siteDescription;
        const ogImage = image ? image : info.defaultImage.file.url;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${info.siteName}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `image`,
                content: ogImage,
              },
              {
                property: `og:image`,
                content: ogImage,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: ogImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="canonical" href={url} />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    info: contentfulSiteInformation {
      siteName
      siteUrl
      siteDescription
      defaultImage {
        file {
          url
        }
      }
    }
  }
`;
