import React from "react";
import { Link } from "gatsby";

const Footer = ({
  data: { whatsApp, footerText, footerLinkText, legalLink },
}) => {
  return (
    <div className="site-footer" id="footer">
      <div className="container">
        &copy; {new Date().getFullYear()} {footerText} -{" "}
        <Link
          rel="noopener noreferrer"
          className="color-inherit"
          to={legalLink}
        >
          {footerLinkText}
        </Link>
        <a
          rel="noopener noreferrer"
          href={`https://api.whatsapp.com/send?phone=34${whatsApp}`}
          className="whatsApp"
          target="_blank"
        >
          <span className="fab fa-whatsapp my-whatsApp"></span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
