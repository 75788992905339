import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.css";

import Header from "./header";
import Footer from "./footer";
import Contact from "./contact";

import "../css/style.css";
import "../css/font-awesome.css";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

const Layout = ({ children, isHome = false }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        header: contentfulSiteInformation {
          siteName
          logo {
            fluid(maxWidth: 400) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        contact: contentfulContacto {
          submitEmail
          title
          firstLabel
          email
          mainLabel
          legalLabel
          legalLabelLink
          legalLink
          btnSubmit
          btnIcon
          legal {
            childMarkdownRemark {
              html
            }
          }
        }
        footer: contentfulSiteInformation {
          whatsApp
          footerText
          footerLinkText
          legalLink
        }
      }
    `}
    render={({ header, footer, contact }) => (
      <>
        <Header data={header} isHome={isHome} />
        <div>
          <main id="home">{children}</main>
        </div>
        <Contact data={contact}></Contact>
        <Footer data={footer} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
