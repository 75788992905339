import { Link } from "gatsby";
import Img from "gatsby-image";
import React, { useState } from "react";
import { useLocation } from "@reach/router";

const Header = ({ data: { logo, siteName } }) => {
  const [menu, setMenu] = useState(false);
  const { pathname } = useLocation();
  const navMenu = [
    { link: "/#home", label: "Inicio" },
    { link: "/#info", label: "Información" },
    { link: "/fotos", label: "Fotos" },
    { link: "/blog", label: "Blog" },
    { link: `${pathname}#contact`, label: "Contacto" },
  ];
  return (
    <header className={`site-header ${menu ? "active" : ""}`}>
      <div className="container">
        <div className="header-main">
          <div className="logo">
            <Link to="/">
              {logo.fluid ? (
                <Img fluid={logo.fluid} alt={`logo ${siteName}`} />
              ) : (
                <span>{siteName}</span>
              )}
            </Link>
          </div>
          <div className="responsive-menu" onClick={() => setMenu(!menu)}>
            <span></span>
          </div>
          <div className="menu">
            <ul onClick={() => setMenu(false)}>
              {navMenu.map(({ link, label }, i) => (
                <li key={i}>
                  <Link to={link}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
