import React, { Component } from "react";
import { Link } from "gatsby";

const Contact = ({
  data: {
    title,
    firstLabel,
    email,
    mainLabel,
    legalLabel,
    legalLabelLink,
    legalLink,
    btnSubmit,
    btnIcon,
    legal,
  },
}) => {
  return (
    <div className="contact section" id="contact">
      <div className="container">
        <div className="section-head">
          <h4 className="text-center">{title}</h4>
        </div>
        <form
          action="https://formspree.io/meqrwkjq"
          name="contact"
          method="POST"
          data-netlify="true"
        >
          <div>
            <label>
              {firstLabel} <input type="text" name="Nombre" required />
            </label>
          </div>
          <div>
            <label>
              {email} <input type="email" name="Email" required />
            </label>
          </div>
          <div>
            <label>
              {mainLabel} <textarea name="Mensaje" required></textarea>
            </label>
          </div>
          <div>
            <label className="checkbox">
              <input type="checkbox" required />
              <span>
                {legalLabel}{" "}
                <Link rel="noopener noreferrer" target="_blank" to={legalLink}>
                  {legalLabelLink}
                </Link>
              </span>
            </label>
          </div>
          <div>
            <button type="submit">
              {btnSubmit}{" "}
              <i className={btnIcon} style={{ marginLeft: "20px" }}></i>
            </button>
          </div>
        </form>
        <div
          className="legal-notice"
          dangerouslySetInnerHTML={{
            __html: legal.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  );
};

export default Contact;
